/* eslint-disable */

import DropdownOption from "./option";
import { DropdownRenderOptionsProps } from "./types";

const hiddenOptions = ["under19", "age20_59", "over60"];

const DropdownRenderOptions = (props: DropdownRenderOptionsProps<any>) => {
	return (
		<>
			{props.options.map((opt) => {
				if (hiddenOptions.includes(opt.content_key)) return;
				let selected = props.value?.id === opt.id;
				let isCategory = opt.is_category === true;
				let isOpen = opt.is_open === true;
				let selectable = opt.selectable === false ? false : true;

				if (
					opt?.content_key !== "totalpopulation" &&
					opt?.id !== "f6e09788-f2a4-4708-9f90-4b6e91398fee"
				) {
					return (
						<DropdownOption
							key={opt.id}
							value={opt}
							selected={selected}
							selectable={selectable}
							isCategory={isCategory}
							isOpen={isOpen}
							onClick={props.onChange}
							onToggle={props.onToggle}
						/>
					);
				}
				return;
			})}
		</>
	);
};

export { DropdownRenderOptions };
